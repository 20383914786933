import VideoListZone from '@containers/HomePage/components/VideoListZone'
import Slide from '@containers/HomePage/Slide'
import { Col, Row } from 'antd'
import React from 'react'
import { useCategoryPage } from './useCategoryPage'

import '@containers/HomePage/styles.scss'

const CategoryPage: React.FC = () => {
  const { fetchMoreData, hasMore, isLoading, videos } = useCategoryPage()

  return (
    <div className='homePageContainer'>
      <Row className='mt-[62px]'>
        <Col span={4} xs={0} md={0} lg={4} className=''>
          <Slide />
        </Col>
        <Col className='h-full' span={20} xs={24} md={24} lg={20}>
          <div>
            <VideoListZone fetchMoreData={fetchMoreData} hasMore={hasMore} loading={isLoading} videos={videos} />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default CategoryPage
