/* eslint-disable no-unused-expressions */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { useHistory } from 'react-router-dom'

import pathUrl from '@routes/path'
import { CONFIG_APP } from '@constants/constants'

import AppI18n from '../../lang/AppI18n'
import imagesAssets from '../../assets/imgs'
import { brandMyVideo, brandTotal, classroomItem, dashboardItem } from '../../containers/HomePage/constant'
import customerBrandsAtom from '../../recoil/customerBrandAtom'
import userInfoAtom from '../../recoil/userInfoAtom'
import videosAtom from '../../recoil/videosAtom'
import { CustomerBranch } from '../../types'
import { CategoryItem } from '../Item'
import BaseModal from '../Modal/BaseModal'
import Svg from '../Svg'
import './menu.scss'

type Props = {
  open: boolean
  onCancel?: () => void
}

const MenuMobile: React.FC<Props> = ({ open, onCancel }) => {
  const history = useHistory()

  const [userInfo] = useRecoilState(userInfoAtom)
  const setCustomerBrands = useSetRecoilState(customerBrandsAtom)
  const customerBrandsStore = useRecoilValue(customerBrandsAtom)
  const [videosStore, setVideosStore] = useRecoilState(videosAtom)
  const { t } = useTranslation()
  const { customerId } = videosStore?.filter ?? {}

  const handleSelectBrand = (item: CustomerBranch) => {
    setVideosStore((value) => ({
      ...value,
      filter: {
        ...value?.filter,
        customerId: item?._id,
      },
    }))
    handleClose()
  }

  const handleClickClassroom = () => {
    history.push(pathUrl.classroom())
    handleSelectBrand(classroomItem)
  }

  const handleHome = () => {
    history.push(pathUrl.home())
    handleSelectBrand(brandTotal)
  }

  const renderOtherLineITem = () => {
    const brands: CustomerBranch[] = [{ ...brandTotal, onClick: handleHome }]

    if (userInfo?.email) {
      brands.push(brandMyVideo)

      const classroomItemMenu = {
        ...classroomItem,
        onClick: handleClickClassroom,
      }

      brands.push(classroomItemMenu)

      brands.push({
        ...dashboardItem,
        onClick: () => {
          window.location.href = CONFIG_APP.dashboardUrl
        },
      })
    }

    return (
      <>
        {brands.map((item) => (
          <CategoryItem
            key={item._id}
            imageActive={item.imgActive}
            isActive={customerId === item._id}
            onClick={() => (item?.onClick ? item.onClick() : handleSelectBrand(item))}
            image={item.img}
            name={t(item.name)}
            isBeta={item.isBeta}
          />
        ))}
        <hr />
        <CategoryItem
          isActive={false}
          onClick={() => null}
          imageActive={imagesAssets.help}
          image={imagesAssets.help}
          name='Trợ giúp'
        />
        <hr />
        <CategoryItem
          isActive={false}
          onClick={() => null}
          imageActive={imagesAssets.feedback}
          image={imagesAssets.feedback}
          name='Phản hồi'
        />
      </>
    )
  }

  const renderLine = (item: CustomerBranch) => {
    return (
      <CategoryItem
        imageActive={imagesAssets.item}
        key={item._id}
        isActive={customerId === item?._id}
        onClick={() => handleSelectBrand(item)}
        image={imagesAssets.item}
        name={item.name}
      />
    )
  }

  const renderCustomerBrands = () => {
    const categoriesMenu = customerBrandsStore?.customerBrands

    return categoriesMenu ? categoriesMenu?.map((item) => renderLine(item)) : <></>
  }

  const handleClose = () => {
    onCancel && onCancel()
  }

  const renderClose = () => {
    return (
      <div onClick={handleClose} className='absolute top-14 right-7'>
        <Svg.Close color='#ffffff' />{' '}
      </div>
    )
  }

  const handleChangeLanguage = (lang: string) => {
    AppI18n.changeLanguage(lang)
  }

  return (
    <BaseModal className='menuMobile bg-transparent' open={open} onCancel={onCancel}>
      <div className='menuMobileWrapper h-full md:justify-center '>
        <div className='bg-white w-[80%] h-full flex overflow-y-auto flex-col justify-between'>
          <div>
            {renderOtherLineITem()}
            {/* <div className='border-y border-[#E5E5E5] p-3 pl-5 text-[#606060]'>
              NHÃN HÀNG
            </div>
            <div className='pt-4'>
              {renderCustomerBrands()}
            </div> */}
          </div>
          <div className='pb-5 pt-5 border-t border-t-[#E5E5E5] px-5'>
            <a href='#'>
              <p className='py-1 text-base font-semibold'>{t('home.about_us')}</p>
            </a>
            <a>
              <p className='py-1 text-base font-semibold'>{t('home.deepview')}</p>
            </a>
            <a>
              <p className='py-1 text-base font-semibold'>{t('home.team')}</p>
            </a>
            <a>
              <p className='py-1 text-base font-semibold'>{t('home.recruit')}</p>
            </a>
            <a>
              <p className='py-1 text-base font-semibold'>{t('home.store')}</p>
            </a>

            <p className='py-1 text-sm mt-5 font-medium'>{t('home.phone')}</p>
            <p className='py-1 text-sm font-medium'>{t('home.email')}</p>
            <p className='py-1 text-sm font-medium'>{t('home.chat')}</p>
            <p className='py-1 mt-5 text-sm font-medium'>{t('home.language')}</p>
            <p className='flex items-center cursor-pointer' onClick={() => handleChangeLanguage('vi')}>
              <img className='mr-3' width={24} height={24} src={imagesAssets.vi} alt='vi' />
              {t('home.vi')}
            </p>
            <p className='flex items-center mt-3 cursor-pointer' onClick={() => handleChangeLanguage('en')}>
              <img className='mr-3' width={24} height={24} src={imagesAssets.eng} alt='EN' />
              {t('home.en')}
            </p>
            <p className='py-1 mt-5 text-sm font-medium'>© 2022 {CONFIG_APP.nameApp}</p>
          </div>
        </div>
        {renderClose()}
      </div>
    </BaseModal>
  )
}

export default MenuMobile
