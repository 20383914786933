import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Input, message, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { useRecoilState } from 'recoil'

import { hideModal, showModal } from '@managers/GlobalModal'
import Slide from '@containers/HomePage/Slide'
import pathUrl from '@routes/path'
import { CheckCircleFilled } from '@ant-design/icons'
import PrimaryBtn from '@components/Button/PrimaryBtn'
import Logger from '@managers/Logger'
import LineOrBase from '@components/Line/LineOrBase'
import appModalAtom from '@recoil/appModalAtom'

import { ModalType } from 'types/enum'

import { authApis, IBodyUpdatePassword } from '../auth.apis'
import { messageResponse } from '../helpers/messageResponse'

const ResetPassword: React.FC = () => {
  const location = useLocation()
  const history = useHistory()
  const [form] = Form.useForm()

  const { t } = useTranslation()

  const searchParams = new URLSearchParams(location?.search)
  const token = searchParams.get('token')

  const [sendEmail, setSendEmail] = useState(false)
  const [, setAppModal] = useRecoilState(appModalAtom)
  const [isLoading, setIsLoading] = useState(false)

  const _handleSubmit = async () => {
    try {
      const fieldsValue = await form.validateFields()

      if (!fieldsValue) return

      setIsLoading(true)

      const { password, password_confirmation } = fieldsValue

      const body: IBodyUpdatePassword = {
        password,
        password_confirmation,
        reset_token: token ?? '',
      }

      const res = await authApis.updatePassword(body)

      if (res.message === 'UPDATED_SUCCESSFULLY') {
        setSendEmail(true)
        form.resetFields()
      } else {
        message.error(messageResponse(res))
      }
    } catch (error: any) {
      Logger.error('_handleSubmit ForgetPassword ', error)
      message.error(messageResponse({ message: error?.message ?? 'INVALID_TOKEN' }))
    } finally {
      setIsLoading(false)
    }
  }

  const renderContent = () => {
    return (
      <div className='flex flex-col justify-center items-center m-auto sm:mt-2 min-h-[95vh] py-3 max-w-[400px]'>
        <Form layout='vertical' form={form}>
          <Row gutter={{ lg: 15 }}>
            <Col xs={24}>
              <div className='font-bold text-2xl text-center'>Đặt lại mật khẩu</div>
            </Col>
            {sendEmail && (
              <>
                <Col xs={24}>
                  <div className='flex justify-center items-center bg-[#E0F4C6] py-4 px-5 gap-3 text-[#2B8700] my-4'>
                    <CheckCircleFilled style={{ color: '#2B8700', fontSize: 24 }} /> {t('auth.updateSuccessPassword')}
                  </div>
                </Col>
              </>
            )}
            <Col xs={24}>
              <Form.Item
                label={t('login.passwordLogin')}
                name='password'
                rules={[
                  { required: true, message: t('validate.require') ?? '' },
                  { min: 8, message: t('login.passMin8') ?? '' },
                ]}
              >
                <Input.Password placeholder={t('login.passwordLoginPlaceholder') ?? ''} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                label={t('login.rePasswordLogin')}
                name='password_confirmation'
                rules={[
                  { required: true, message: t('validate.require') ?? '' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject(new Error(`${t('login.notMatch')}`))
                    },
                  }),
                ]}
              >
                <Input.Password placeholder={t('login.rePasswordLoginPlaceholder') ?? ''} style={{ width: '100%' }} />
              </Form.Item>
            </Col>

            <Col xs={24}>
              <Form.Item shouldUpdate>
                {() => (
                  <PrimaryBtn
                    className='w-full md:!rounded-[100px] md:mt-4'
                    disabled={!!form.getFieldsError().filter(({ errors }) => errors.length).length || isLoading}
                    onClick={_handleSubmit}
                    loading={isLoading}
                  >
                    {t('auth.updatePassword')}
                  </PrimaryBtn>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <div className='w-full flex justify-center flex-col items-center'>
          <LineOrBase />

          <Button type='link' className='link-yellow' onClick={() => setAppModal({ mode: ModalType.signIn })}>
            {t('login.sign_in')}
          </Button>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className='homePageContainer bg-[#ffffff] min-h-[95vh]'>
        <Row className='mt-[62px] h-full'>
          <Col span={4} xs={0} md={0} lg={4} className=''>
            <Slide />
          </Col>
          <Col className='h-full' span={20} xs={24} md={24} lg={20}>
            {renderContent()}
          </Col>
        </Row>
      </div>
    </>
  )
}

export default ResetPassword
