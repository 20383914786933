import React from 'react'
import imagesAssets from '@assets/imgs'
import { useRecoilState } from 'recoil'

import userInfoAtom from '@recoil/userInfoAtom'
import Svg from '@components/Svg'
import PrimaryBtn from '@components/Button/PrimaryBtn'
import { useHistory } from 'react-router-dom'
import pathUrl from '@routes/path'

export enum EnoughType {
  FREE = 'free',
  AVAILABLE = 'available',
  PENDING_UPGRADE = 'pending_upgrade',
}

interface Props {
  enoughType?: EnoughType
  onCloseModal: () => void
}

const TimeOutModal: React.FC<Props> = ({ onCloseModal, enoughType = EnoughType.FREE }) => {
  const history = useHistory()
  const [userInfo] = useRecoilState(userInfoAtom)

  const limit_time = userInfo?.customer?.minutes_limit ?? 0

  const handleClose = () => {
    onCloseModal()
  }

  const redirectUpgradeAccount = () => {
    handleClose()
    history.push(pathUrl.upgradeAccount())
  }

  const renderContent = () => {
    return (
      <div className='text-center mt-4'>
        {enoughType === EnoughType.FREE && (
          <>
            <div className='text-lg '>Bạn đã dùng hết {limit_time} phút xem miễn phí trong ngày</div>
            <div className='text-lg '>Hãy nâng cấp để tiếp tục video nhé!</div>
          </>
        )}
        {enoughType === EnoughType.AVAILABLE && (
          <>
            <div className='text-lg '>Bạn đã dùng hết số phút khả dụng</div>
            <div className='text-lg '>Hãy nâng cấp hoặc quay lại vào hôm sau để tiếp tục xem video nhé!</div>
          </>
        )}
        {enoughType === EnoughType.PENDING_UPGRADE && (
          <>
            <div className='text-lg '>Yêu cầu nâng cấp gói của bạn đang được chờ duyệt.</div>
            <div className='text-lg '>Chúng tôi sẽ thông báo qua email khi yêu cầu được hoàn tất.</div>
          </>
        )}
      </div>
    )
  }

  const renderActionButton = () => {
    if (enoughType === EnoughType.PENDING_UPGRADE) {
      return (
        <div className='flex justify-center gap-6 mb-7 mt-4'>
          <PrimaryBtn
            className='uppercase !font-normal min-w-[200px] !bg-[#ffffff] !text-[#000000] py-2 h-fit px-12'
            onClick={handleClose}
          >
            Tôi đã hiểu
          </PrimaryBtn>
        </div>
      )
    }

    return (
      <div className='flex justify-center gap-6 mb-7 mt-4'>
        <PrimaryBtn
          className='uppercase !font-normal min-w-[200px] !bg-[#2864E9] py-2 h-fit px-12'
          onClick={redirectUpgradeAccount}
        >
          NÂNG CẤP GÓI
        </PrimaryBtn>
      </div>
    )
  }

  return (
    <div className='limit-number-wrapper'>
      <div className='title text-[#3083ff] bg-cover flex flex-col justify-center items-center relative'>
        <div className='font-bold text-4xl text-[#D32F2F]'>Hết thời gian xem</div>
        <div className='absolute right-0 top-0 cursor-pointer' onClick={handleClose}>
          <Svg.Close />
        </div>
      </div>

      <div className='flex justify-center  '>
        <img src={imagesAssets.timeOut} alt='zz' />
      </div>

      {renderContent()}

      {renderActionButton()}
    </div>
  )
}

export default TimeOutModal
