import React from 'react'
import imagesAssets from '@assets/imgs'
import { useRecoilState } from 'recoil'

import userInfoAtom from '@recoil/userInfoAtom'
import MainButton from '@components/Button/MainButton'
import Svg from '@components/Svg'

interface Props {
  onCloseModal: () => void
}

const ShowLimitNumberModal: React.FC<Props> = ({ onCloseModal }) => {
  const [userInfo] = useRecoilState(userInfoAtom)

  const limit_time = userInfo?.customer?.minutes_limit ?? 0

  const handleClose = () => {
    onCloseModal()
  }

  return (
    <div className='limit-number-wrapper'>
      <div className='absolute w-full top-0 left-0'>
        <img className='h-full w-full' src={imagesAssets.bgLimitNumber} alt='zz' />
      </div>
      <div className='title text-[#3083ff] h-[150px] bg-cover flex flex-col justify-center items-center relative'>
        <div className='font-400 text-5xl z-10'>Chúc mừng</div>
        <div className='absolute right-0 top-0 cursor-pointer' onClick={handleClose}>
          <Svg.Close />
        </div>
      </div>

      <div className='text-center'>
        <div className='text-lg '>
          Chào mừng bạn đến với Deepread. Hãy bắt đầu trải nghiệm {limit_time} phút nghe sách chú tâm miễn phí mỗi ngày
          bạn nhé!
        </div>
        <div className='flex items-center justify-center my-4'>
          <span className='font-bold text-3xl text-[#000]'>{limit_time}</span>
          <span className='text-lg text-[#000] mx-2'>phút</span>
          <span className='font-bold text-3xl text-[#000]'>00</span>
          <span className='text-lg text-[#000] mx-2'>giây</span>
        </div>
      </div>

      <div className='flex justify-center mb-7'>
        <MainButton onClick={handleClose}>Xác nhận</MainButton>
      </div>
    </div>
  )
}

export default ShowLimitNumberModal
