/* eslint-disable consistent-return */
import * as React from 'react'
import { Button, Collapse, Form, Popconfirm, Table, message } from 'antd'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'

import { postResultRecord } from '@apis'
import { DEMO_ACCOUNT } from '@constants/constants'

import pathUrl from '@routes/path'
// store
import appModalAtom from '@recoil/appModalAtom'
// components
import { useDemoAccount } from '@managers/DemoAccount/helpers'
import TerminalButton from '@components/Button/TerminalButton'
import { ModalType } from 'types/enum'

import PrimaryBtn from '../../Button/PrimaryBtn'
import BaseModal, { BaseModalProps } from '../BaseModal'
import '../styles.scss'
import './review.scss'
import useReviewPointColumn from './useReviewPointColumn'
import { ResultItemType, UserType, VideoType } from '../../../types'
import resultRecordAtom, { sumPointSelector } from '../../../recoil/resultRecordAtom'
import userInfoAtom, { concentrationLevelSelector } from '../../../recoil/userInfoAtom'
import detailVideoAtom from '../../../recoil/detailVideoAtom'
import Svg from '../../Svg'
import SecondaryBtn from '../../Button/SecondaryBtn'

const { Panel } = Collapse

const NAME_PANEL_RESULT = '1'

export interface IRecord {
  concentration_level: number
}

interface Props extends BaseModalProps {
  data: VideoType
  onSubmit: () => void
  onReWatch: () => void
  isAnsweredQuestion: boolean
}

function ReviewPointModal(props: Props) {
  const { onSubmit, onReWatch, isAnsweredQuestion, data } = props

  const history = useHistory()
  const { t } = useTranslation()

  const { classID } = useParams<{ videoID: string; classID?: string }>()

  const { isDemoAccount } = useDemoAccount()

  const [form] = Form.useForm()

  const [appModal, setAppModal] = useRecoilState(appModalAtom)
  const [userInfo, setUserInfo] = useRecoilState(userInfoAtom)
  const [detailVideo] = useRecoilState(detailVideoAtom)
  const [resultRecord] = useRecoilState(resultRecordAtom)
  const configColumn = useReviewPointColumn()
  const { resultPoint, quizzesPoint, sumPoint } = useRecoilValue(sumPointSelector)
  const concentrationLevel = useRecoilValue(concentrationLevelSelector)

  const [activeKey, setActiveKey] = React.useState<string[] | string>([])
  const [isSubmitOtherAccount, setIsSubmitOtherAccount] = React.useState(false)

  const textPoint = data?.is_sponsor ? t('video.vnd') : t('video.score')

  const calculateConcentrationResult = () => {
    if (!detailVideo) {
      return 0
    }

    const { activeQuestionIndex = 0, questions = [], quizzes = [] } = detailVideo

    try {
      const resultCorrect = resultRecord.result.filter((item) => item.result)
      const quizCorrect = resultRecord.quizzes.filter((item) => item.result)
      const totalCorrect = resultCorrect.length + quizCorrect.length

      const question = questions[activeQuestionIndex]

      const total = question.answers.length + quizzes.length

      const concentrationLevel = (totalCorrect / total) * 100

      return Math.floor(concentrationLevel)
    } catch (error) {
      return 0
    }
  }

  const handleSubmitRecord = async () => {
    if (!detailVideo) return

    const question = detailVideo?.questions?.[detailVideo.activeQuestionIndex ?? 0]
    const concentration_level = calculateConcentrationResult()

    try {
      await postResultRecord({
        video: {
          id: detailVideo.id,
          name: detailVideo.name,
          url: detailVideo.video,
        },
        question: {
          id: question?.id,
          word: question?.word,
        },
        url: detailVideo?.video,
        point: sumPoint,
        result: resultRecord.result,
        quizzes: resultRecord.quizzes,
        class_id: classID,
        // eslint-disable-next-line camelcase
        concentration_level: concentration_level / 100,
      })

      return 1
    } catch (error: any) {
      message.error(error?.message ?? 'Thao Tác Không Thành Công')
      return undefined
    }
  }

  const handleSubmit = async () => {
    const fieldsValue = await form.validateFields()
    setUserInfo({ ...userInfo, ...fieldsValue })
    await handleSubmitRecord()
    onSubmit()
  }

  const checkConcentrationLevel = () => {
    return calculateConcentrationResult() < concentrationLevel
  }

  const checkDisableSubmit = () => {
    if (userInfo?.customer?.restrict_mode) {
      return checkConcentrationLevel()
    }

    return false
  }

  const renderReWatch = () => {
    return (
      <SecondaryBtn className='w-[180px]' onClick={onReWatch}>
        {t('video.re_watch')}
      </SecondaryBtn>
    )
  }

  const renderSendPoint = () => {
    return isAnsweredQuestion ? (
      <Popconfirm
        placement='top'
        title={t('video.warning_score')}
        onConfirm={handleSubmit}
        okText={t('video.agree')}
        cancelText={t('video.cancel')}
      >
        <PrimaryBtn className='w-[180px]'>{t('video.reportScore')}</PrimaryBtn>
      </Popconfirm>
    ) : (
      <PrimaryBtn className='w-[180px]' onClick={handleSubmit}>
        {t('video.reportScore')}
      </PrimaryBtn>
    )
  }

  const submitBtn = undefined

  const renderConcentrationLevel = () => {
    const concentrationLevel = calculateConcentrationResult()
    const isValid = checkConcentrationLevel()

    return (
      <div>
        {isValid ? (
          <div className='text-[#D32F2F] mb-3'>
            {t('video.showRequire', { concentrationLevel })} <br />
            {t('video.canReview')}
          </div>
        ) : (
          <></>
        )}

        <div className='mb-3'>
          {t('video.concentration_level')}: <span className='text-[#1C62B9] font-semibold'>{concentrationLevel}%</span>
        </div>
      </div>
    )
  }

  const handleShowResultDetail = () => {
    setActiveKey(activeKey === NAME_PANEL_RESULT ? [] : NAME_PANEL_RESULT)
  }

  const handleAfterCloseModal = () => {
    setIsSubmitOtherAccount(false)
  }

  const handleRequestOtherAccount = (isSign = true) => {
    setAppModal({
      mode: isSign ? ModalType.signIn : ModalType.signUp,
      data: {
        isDemoAccount: true,
        onCancel: async (userData?: UserType) => {
          if (userData?.email) {
            if (userData?.email === DEMO_ACCOUNT.email) {
              message.error(t('video.error_demo_account'))
            } else {
              setAppModal({ mode: ModalType.close })
              const resultSubmitRecord = await handleSubmitRecord()

              if (resultSubmitRecord) {
                setIsSubmitOtherAccount(true)
              }
            }
          }
          setAppModal({ mode: ModalType.close })
        },
      },
    })
  }

  const handleClickRegister = () => {
    handleRequestOtherAccount(false)
  }

  const handleClickLogin = () => {
    handleRequestOtherAccount(true)
  }

  const handleRedirectOtherVideo = () => {
    history.push(pathUrl.home())
  }

  const renderFooter = () => {
    const disableSubmit = checkDisableSubmit()

    if (isDemoAccount) {
      return (
        <div>
          <div className='text-[#34A853] mb-7'>
            {t('video.suggestLogin')}{' '}
            <span className='text-[#1F91FA] cursor-pointer' onClick={handleClickLogin}>
              {t('video.login')}{' '}
            </span>
          </div>
          <div className='flex justify-center mt-auto gap-3 w-full mb-8'>
            <div>
              <TerminalButton className='w-[180px] uppercase' onClick={onReWatch}>
                {t('video.skip')}
              </TerminalButton>
            </div>
            <div>
              <PrimaryBtn className='w-[180px] uppercase' onClick={handleClickRegister}>
                {t('video.register')}
              </PrimaryBtn>
            </div>
          </div>
        </div>
      )
    }

    if (isSubmitOtherAccount) {
      return (
        <div>
          <div className='flex justify-center mt-auto gap-3 w-full mb-8'>
            <div>
              <TerminalButton className='w-[180px] uppercase' onClick={onReWatch}>
                {t('video.re_watch')}
              </TerminalButton>
            </div>
            <div>
              <PrimaryBtn className='w-[180px] uppercase' onClick={handleRedirectOtherVideo}>
                {t('video.watch_other_video')}
              </PrimaryBtn>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className='flex justify-center mt-auto gap-3 w-full mb-8'>
        <div>{renderReWatch()}</div>
        <div>{disableSubmit ? <></> : renderSendPoint()}</div>
      </div>
    )
  }

  const renderShowSubmitRecord = () => {
    if (isSubmitOtherAccount) {
      return <div className='text-[#34A853] font-bold text-2xl mb-6'>{t('video.point_sended')}</div>
    }

    return <></>
  }

  const renderContent = () => {
    return (
      <div className='reviewPointContainer'>
        {renderShowSubmitRecord()}
        {renderConcentrationLevel()}
        <div className='pointWrapper'>
          <div className='flex justify-center items-center '>
            <div>
              {t('video.youReceive')}:
              <span className='ml-1 text-[#1C62B9] font-semibold'>
                {sumPoint} {textPoint}
              </span>
            </div>
            <div>
              <Button type='link' onClick={handleShowResultDetail}>
                {activeKey.length ? t('video.hidden_detail') : t('video.more_detail')}
              </Button>
            </div>
          </div>
          <Collapse ghost expandIcon={() => null} activeKey={activeKey}>
            <Panel header={<></>} key={NAME_PANEL_RESULT}>
              <Table<ResultItemType>
                bordered
                columns={configColumn}
                dataSource={resultRecord.result}
                pagination={false}
              />
              <div className='m-3'>
                {t('video.resultReport')}
                <span className='text-[#1C62B9] font-semibold text-sm ml-1 mr-1'>
                  {`${resultPoint} ${textPoint} + ${quizzesPoint} ${textPoint}`}
                </span>
                ({t('video.quiz')})
              </div>
            </Panel>
          </Collapse>
        </div>
        {renderFooter()}
      </div>
    )
  }

  return (
    <BaseModal
      title={
        <div className='flex flex-row justify-center text-[#1F91FA] '>
          <Svg.CheckCircle />
          <div className='ml-3'>{t('video.watched_message')}</div>
        </div>
      }
      footer={false}
      className='review-modal'
      afterClose={handleAfterCloseModal}
      {...props}
    >
      {renderContent()}
    </BaseModal>
  )
}

export default ReviewPointModal
