import * as React from 'react'
import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { useTranslation } from 'react-i18next'
import { Form, Input, Row, Col, InputNumber, Button, Select } from 'antd'
import { useHistory } from 'react-router-dom'

import pathUrl from '@routes/path'
import authAtom from '@containers/Auth/atom/authAtom'
import { CheckCircleFilled } from '@ant-design/icons'

import PrimaryBtn from '../../Button/PrimaryBtn'
import BaseModal, { BaseModalProps } from '../BaseModal'
import userInfoAtom from '../../../recoil/userInfoAtom'
import { postSignIn, postSignUp } from '../../../apis'
import appModalAtom from '../../../recoil/appModalAtom'
import { ModalType } from '../../../types/enum'
import { UserType } from '../../../types'

enum IScreenType {
  DEFAULT,
  CHECK_EMAIL,
}

interface Props extends BaseModalProps {
  data?: any
}

function UserModal(props: Props) {
  const { t } = useTranslation()
  const history = useHistory()
  const [appModal, setAppModal] = useRecoilState(appModalAtom)
  const { mode } = appModal
  // const [mode, setMode] = React.useState<ModalType>(appModal.modal);
  const [form] = Form.useForm()
  const [, setUserInfo] = useRecoilState(userInfoAtom)
  const [, setAuth] = useRecoilState(authAtom)
  const [loading, setLoading] = useState(false)
  const [screenType, setScreenType] = useState<IScreenType>(IScreenType.DEFAULT)
  const [isSuccessRegister, setIsSuccessRegister] = useState(false)

  const handleClose = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    userInfoResult?: UserType,
    params?: Record<string, any>,
  ) => {
    if (props?.data?.onCancel) {
      props?.data?.onCancel(userInfoResult, params)
    } else {
      // eslint-disable-next-line no-unused-expressions
      props?.onCancel && props?.onCancel(e)
    }
  }

  const handleSubmit = async () => {
    const fieldsValue = await form.validateFields()
    try {
      setLoading(true)
      let userInfoResult: UserType = {}
      if (mode === ModalType.signIn) {
        const { password, email } = fieldsValue
        const res = await postSignIn({ password, email })
        userInfoResult = { ...res?.user }
        setUserInfo(userInfoResult)
        setAuth({ accessToken: res.access_token })
        handleClose({} as any, userInfoResult)
      } else {
        const {
          firstName,
          lastName,
          email,
          phone,
          userType,
          company,
          companyAddress,
          refCode,
          password,
          password_confirmation,
        } = fieldsValue
        const res = await postSignUp({
          firstName,
          lastName,
          email,
          phone,
          userType,
          company,
          companyAddress,
          refCode,
          password,
          password_confirmation,
        })

        if (props.data?.isDemoAccount) {
          userInfoResult = { ...res?.user }
          setUserInfo(userInfoResult)

          handleClose({} as any, userInfoResult)
        } else {
          setIsSuccessRegister(true)
          // setScreenType(IScreenType.CHECK_EMAIL)
        }
      }
      form.resetFields()
    } finally {
      setLoading(false)
    }
  }

  const _handleForgetPassword = () => {
    handleClose({} as any)
    history.push(pathUrl.forgetPassword())
  }

  useEffect(() => {
    if (!props.open) {
      setScreenType(IScreenType.DEFAULT)
    }
  }, [props.open])

  const renderLine = () => {
    return (
      <div className='flex justify-center relative my-7'>
        <div className='absolute  border-t border-t-[#D0D5DD] top-[10px] left-0 w-full' />
        <div className='z-10'>
          <span className='bg-[#ffffff] px-2 font-medium text-[#697077] text-sm'>Hoặc</span>
        </div>
      </div>
    )
  }

  const handleRedirectSignUp = () => {
    setIsSuccessRegister(false)
    setAppModal({ mode: ModalType.signUp, data: props.data })
  }

  const renderSignInForm = () => (
    <>
      <Form.Item
        label={t('login.emailLogin')}
        name='email'
        rules={[{ required: true, message: t('validate.require') ?? '' }]}
      >
        <Input placeholder={t('login.emailLoginPlaceholder') ?? ''} />
      </Form.Item>
      <Form.Item
        label={t('login.passwordLogin')}
        name='password'
        rules={[{ required: true, message: t('validate.require') ?? '' }]}
      >
        <Input.Password placeholder={t('login.passwordLoginPlaceholder') ?? ''} />
      </Form.Item>
      <Form.Item shouldUpdate>
        {() => (
          <PrimaryBtn
            className='w-full md:!rounded-[100px] md:mt-4'
            disabled={!!form.getFieldsError().filter(({ errors }) => errors.length).length}
            onClick={handleSubmit}
            loading={loading}
          >
            {t('login.sign_in')}
          </PrimaryBtn>
        )}
      </Form.Item>
      <div className='flex justify-center'>
        <Button type='link' className='font-semibold' onClick={_handleForgetPassword}>
          Quên mật khẩu?
        </Button>
      </div>
      {renderLine()}
      <div className='flex  justify-center items-center pb-5'>
        {t('login.no_user_code')}.
        <Button type='link' className='link-yellow' onClick={handleRedirectSignUp}>
          {t('login.sign_up')}
        </Button>
      </div>
    </>
  )

  const renderSignUpForm = () => (
    <>
      {isSuccessRegister && (
        <div className='bg-[#E0F4C6] p-3 mb-2'>
          <div className='flex justify-center items-center gap-3 text-[#2B8700] font-medium'>
            <CheckCircleFilled style={{ color: '#2B8700', fontSize: 24 }} /> Vui lòng kiểm tra email để tiếp tục!
          </div>
        </div>
      )}
      <Row gutter={{ lg: 15 }}>
        <Col xs={24} lg={12}>
          <Form.Item
            label={t('login.last_name')}
            name='lastName'
            rules={[{ required: true, message: t('validate.require') ?? '' }]}
          >
            <Input placeholder={t('login.last_name') ?? ''} />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            label={t('login.first_name')}
            name='firstName'
            rules={[{ required: true, message: t('validate.require') ?? '' }]}
          >
            <Input placeholder={t('login.first_name') ?? ''} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ lg: 15 }}>
        <Col xs={24} lg={12}>
          <Form.Item
            label={t('login.email_address')}
            name='email'
            rules={[
              { required: true, message: t('validate.require') ?? '' },
              { type: 'email', message: t('login.email') ?? '' },
            ]}
          >
            <Input placeholder={t('login.email_address') ?? ''} />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            label={t('login.phone')}
            name='phone'
            rules={[{ required: true, message: t('validate.require') ?? '' }]}
          >
            <InputNumber placeholder={t('login.phone') ?? ''} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label={t('login.referral_code')}
        name='refCode'
        rules={[{ required: false, message: t('login.referral_code') ?? '' }]}
      >
        <Input placeholder={t('login.referralCodeOrSkip') ?? ''} style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item
        label={t('login.passwordLogin')}
        name='password'
        rules={[
          { required: true, message: t('validate.require') ?? '' },
          { min: 8, message: t('login.passMin8') ?? '' },
        ]}
      >
        <Input.Password placeholder={t('login.passwordLoginPlaceholder') ?? ''} style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item
        label={t('login.rePasswordLogin')}
        name='password_confirmation'
        rules={[
          { required: true, message: t('validate.require') ?? '' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve()
              }
              return Promise.reject(new Error(`${t('login.notMatch')}`))
            },
          }),
        ]}
      >
        <Input.Password placeholder={t('login.rePasswordLoginPlaceholder') ?? ''} style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item shouldUpdate>
        {() => (
          <PrimaryBtn
            className='w-full md:!rounded-[100px] md:mt-4'
            disabled={!!form.getFieldsError().filter(({ errors }) => errors.length).length}
            onClick={handleSubmit}
            loading={loading}
          >
            {t('login.sign_up')}
          </PrimaryBtn>
        )}
      </Form.Item>

      {renderLine()}

      <div className='flex  justify-center items-center pb-5'>
        {t('login.have_user_code')}
        <Button
          type='link'
          className='link-yellow'
          onClick={() => setAppModal({ mode: ModalType.signIn, data: props.data })}
        >
          {t('login.sign_in')}
        </Button>
      </div>
    </>
  )

  return (
    <BaseModal className='userModal' {...props} closable onCancel={handleClose}>
      <Form className='userWrapper' layout='vertical' form={form}>
        <div className='title md:text-[#1F91FA] md:mb-4 md:font-semibold'>{`${
          mode === ModalType.signIn ? t('login.sign_in') : t('login.sign_up')
        }`}</div>
        {mode === ModalType.signIn ? renderSignInForm() : renderSignUpForm()}
      </Form>
    </BaseModal>
  )
}

export default UserModal
