import React, { useImperativeHandle, useState } from 'react'
import BaseModal from '@components/Modal/BaseModal'

import { ModalProps, modalRef } from './ModalManager'

const ModalGlobal: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [modalProps, setModalProps] = useState<ModalProps>()

  const showModal = (modalContent: ModalProps) => {
    setModalProps(modalContent)
    setIsOpen(true)
  }

  const hideModal = () => {
    setModalProps(undefined)
    setIsOpen(false)
  }

  const handleCancel = () => {
    if (modalProps?.onCancel) {
      modalProps?.onCancel()
    } else {
      hideModal()
    }
  }

  useImperativeHandle(modalRef, () => ({
    showModal,
    hideModal,
  }))

  return (
    <BaseModal className='app-base-modal' open={isOpen} onCancel={handleCancel} {...modalProps?.config}>
      {modalProps?.content}
    </BaseModal>
  )
}

export default ModalGlobal
