import { useEffect, useState } from 'react'
import { Col, Row } from 'antd'
import { useRecoilState } from 'recoil'
import { useParams, Route, Redirect } from 'react-router-dom'

import PrivateRouter from '@routes/PrivateRouter'

import './styles.scss'

import Slide, { overview } from './Slide'
import Categories from './Categories'
import videosAtom from '../../recoil/videosAtom'
import { apis, IVideoParams } from '../../apis'
import { brandMyVideo, brandTotal, categoryTotal, classroomItem } from './constant'
import videoListAtom from '../../recoil/videoListAtom'
import { VideoType } from '../../types'
import useInfoProfile from '../../components/useHook/useInfoProfile'
import { ClassroomList } from '../SLO/Classroom/pages/List'
import { Lessons } from '../SLO/Classroom/pages/Lessons'
import pathUrl from '../../routes/path'
import VideoListZone from './components/VideoListZone'

const ITEM_PER_PAGE = 12

interface IParams {
  category?: string
}

function HomePage() {
  const params = useParams<IParams>()

  const { category } = params ?? {}
  const { userInfo } = useInfoProfile()
  const [videoList] = useRecoilState(videosAtom)
  const [videoCommonList, setVideoCommonList] = useRecoilState(videoListAtom)
  const { filter } = videoList
  const { loading, videos } = videoCommonList

  const [currentPage, setCurrentPage] = useState(1)
  const [hasMore, setHasMore] = useState(false)

  const getApi = async (page = 1) => {
    try {
      setHasMore(false)
      const paramSearch: IVideoParams = {
        page_limit: ITEM_PER_PAGE,
        page,
      }
      let isAuth = false

      if (filter?.category && filter?.category !== categoryTotal.slug) {
        paramSearch.category = filter?.category
      }

      if (category) {
        paramSearch.category = category
      }

      if (userInfo?.customer_id) {
        paramSearch.customer_id = userInfo?.customer_id
      }

      if (filter?.search) {
        paramSearch.q = filter?.search
      }

      // brand
      console.log('filter', filter)
      if (filter?.customerId) {
        if (filter?.customerId === brandTotal._id) {
          // paramSearch.customer_id = undefined
        } else if (filter?.customerId === brandMyVideo._id) {
          paramSearch.purchased = true
          isAuth = true
        } else {
          // paramSearch.customer_id = filter?.customerId
        }
      }

      const response = await apis.getVideoList(paramSearch, isAuth)

      setVideoCommonList((value) => {
        // eslint-disable-next-line no-unsafe-optional-chaining
        const videosArr = [...value.videos, ...response?.videos]

        const videoObjectMap = videosArr.reduce((videoMap: Record<string, VideoType>, video: VideoType) => {
          videoMap[video.id!] = video
          return videoMap
        }, {})

        return {
          ...value,
          loading: false,
          videos: videosArr,
          videoMap: { ...videoObjectMap },
        }
      })

      if (response?.videos?.length < ITEM_PER_PAGE) {
        setHasMore(false)
      } else {
        setCurrentPage(page + 1)
        setHasMore(true)
      }
    } catch (error) {
      console.log('error getApi', error)
    }
  }

  const fetchMoreData = async () => {
    if (!hasMore || videos.length === 0) {
      return
    }

    await getApi(currentPage)
  }

  const renderMain = () => {
    if (filter.customerId && [classroomItem._id].includes(filter.customerId)) {
      return <></>
    }

    return (
      <>
        {category ? (
          <></>
        ) : (
          <>
            <div className='fixed z-50 h-[62px] w-full'>
              <Categories />
            </div>
            <div className='mt-[62px]' />
          </>
        )}
        <VideoListZone fetchMoreData={fetchMoreData} hasMore={hasMore} loading={loading} videos={videos} />
      </>
    )
  }

  useEffect(() => {
    if (filter?.customerId === overview._id) {
      return
    }

    setVideoCommonList((value) => ({
      ...value,
      videos: [],
      loading: true,
    }))

    getApi(1)
  }, [filter?.category, filter?.customerId, userInfo?.customer_id, filter?.search])

  const renderRoutesAuth = () => {
    return (
      <>
        <Route exact path={pathUrl.classroom()} render={() => <PrivateRouter component={<ClassroomList />} />} />
        <Route exact path={pathUrl.lessons()} render={() => <PrivateRouter component={<Lessons />} />} />
        {/* <Route path='*'>
          <Redirect to={pathUrl.home()} />
        </Route> */}
      </>
    )
  }

  return (
    <div className='homePageContainer'>
      <Row className='mt-[62px]'>
        <Col span={4} xs={0} md={0} lg={4} className=''>
          <Slide />
        </Col>
        <Col className='h-full' span={20} xs={24} md={24} lg={20}>
          {renderRoutesAuth()}
          <Route exact path={pathUrl.home()} render={renderMain} />
        </Col>
      </Row>
    </div>
  )
}

export default HomePage
